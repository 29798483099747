/**
 * 格式化时间
 * 
 * @param  {time} 时间
 * @param  {cFormat} 格式
 * @return {String} 字符串
 *
 * @example formatTime('2018-1-29', '{y}/{m}/{d} {h}:{i}:{s}') // -> 2018/01/29 00:00:00
 */
export function formatTime(time, cFormat) {
    if (arguments.length === 0) return null
    if ((time + '').length === 10) {
        time = +time * 1000
    }

    //var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}',
    var format = cFormat || '{h}:{i}',
        date
    if (typeof time === 'object') {
        date = time
    } else {
        date = new Date(time)
    }

    var formatObj = {
        //y: date.getFullYear(),
        //m: date.getMonth() + 1,
        //d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    }
    var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        var value = formatObj[key]
        if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
        if (result.length > 0 && value < 10) {
            value = '0' + value
        }
        return value || 0
    })
    return time_str
}


export function formatDate(time, cFormat) {
    if (arguments.length === 0) return null
    if ((time + '').length === 10) {
        time = +time * 1000
    }

    var format = cFormat || '{y}年{m}月{d}日',
        date
    if (typeof time === 'object') {
        date = time
    } else {
        date = new Date(time)
    }

    var formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    }
    var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        var value = formatObj[key]
        if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
        if (result.length > 0 && value < 10) {
            value = '0' + value
        }
        return value || 0
    })
    return time_str
}


/**
 * 返回指定长度的月份集合
 * 
 * @param  {time} 时间
 * @param  {len} 长度
 * @param  {direction} 方向：  1: 前几个月;  2: 后几个月;  3:前后几个月  默认 3
 * @return {Array} 数组
 * 
 * @example   getMonths('2018-1-29', 6, 1)  // ->  ["2018-1", "2017-12", "2017-11", "2017-10", "2017-9", "2017-8", "2017-7"]
 */
    export function getMonths(time, len, direction) {
    var mm = new Date(time).getMonth() + 1,
        yy = new Date(time).getFullYear(),
        direction = isNaN(direction) ? 3 : direction,
        index = mm;

    var cutMonth = function(index) {
        var arr
        if (direction === 1) {
            arr = formatPre(index).reverse()
        } else if (direction === 2) {
            arr = formatNext(index)
        } else {
            arr = formatPre(index).reverse().slice(len / 2).concat(formatNext(index).slice(1, len / 2 + 1))
        }
        return arr.sort(function(t1, t2) {
            return new Date(t1).getTime() - new Date(t2).getTime()
        })
    }

    var formatPre = function(index) {
        var currNum = index,
            preNum = 0,
            currArr = [],
            preArr = []
        if (index - len < 0) {
            preNum = len - currNum
        }
        for (var i = 0; i < currNum; i++) {
            currArr.push([yy + '-' + (currNum - i)])
        }
        for (var i = 1; i <= preNum; i++) {
            preArr.push([(yy - Math.ceil(i / 12)) + '-' + (12 - (i - 1) % 12)])
        }
        return currArr.concat(preArr)
    }

    var formatNext = function(index) {
        var currNum = 12 - index,
            nextNum = 0,
            currArr = [],
            nextArr = []
        if (len - currNum > 0) {
            nextNum = len - currNum
        }
        for (var i = 0; i <= currNum; i++) {
            currArr.push([yy + '-' + (index + i)])
        }
        for (var i = 1; i < nextNum; i++) {
            nextArr.push([(yy + Math.ceil(i / 12)) + '-' + (i % 13 === 0 ? 1 : i % 13)])
        }
        return currArr.concat(nextArr)
    }
    return cutMonth(index)
}



/**
 * 返回指定长度的天数集合
 * 
 * @param  {time} 时间
 * @param  {len} 长度
 * @param  {direction} 方向： 1: 前几天;  2: 后几天;  3:前后几天  默认 3
 * @return {Array} 数组
 *
 * @example date.getDays('2018-1-29', 6) // -> ["2018-1-26", "2018-1-27", "2018-1-28", "2018-1-29", "2018-1-30", "2018-1-31", "2018-2-1"]
 */
    export function getDays(time, len, diretion) {
    var tt = new Date(time)
    var getDay = function(day) {
        var t = new Date(time)
        t.setDate(t.getDate() + day)
        var m = t.getMonth() + 1
        return t.getFullYear() + '-' + m + '-' + t.getDate()
    }
    var arr = []
    if (diretion === 1) {
        for (var i = 1; i <= len; i++) {
            arr.unshift(getDay(-i))
        }
    } else if (diretion === 2) {
        for (var i = 1; i <= len; i++) {
            arr.push(getDay(i))
        }
    } else {
        for (var i = 1; i <= len; i++) {
            arr.unshift(getDay(-i))
        }
        arr.push(tt.getFullYear() + '-' + (tt.getMonth() + 1) + '-' + tt.getDate())
        for (var i = 1; i <= len; i++) {
            arr.push(getDay(i))
        }
    }
    return diretion === 1 ? arr.concat([tt.getFullYear() + '-' + (tt.getMonth() + 1) + '-' + tt.getDate()]) :
        diretion === 2 ? [tt.getFullYear() + '-' + (tt.getMonth() + 1) + '-' + tt.getDate()].concat(arr) : arr
}


/**
 * @param  {s} 秒数
 * @return {String} 字符串 
 *
 * @example formatHMS(3610) // -> 1h0m10s
 */
export function formatHMS(s) {
    let str, hour, min, sec, shour, smin, ssec
    if (s > 3600) {
        hour = Math.floor(s / 3600)
        shour = hour<10? '0'+hour: hour
        min =  Math.floor(s % 3600 / 60)
        smin = min<10? '0'+min:min
        sec= s % 60
        ssec = sec<10? '0'+sec: sec
        // str = Math.floor(s / 3600) + 'h' + Math.floor(s % 3600 / 60) + 'm' + s % 60 + 's'
        str = shour+":"+smin+":"+ssec
    } else if (s > 60) {
        shour = "00"
        min =  Math.floor(s / 60)
        smin = min<10? '0'+min:min
        sec= s % 60
        ssec = sec<10? '0'+sec: sec
        // str = Math.floor(s / 60) + 'm' + s % 60 + 's'
        str = shour+":"+smin+":"+ssec
    } else {
        shour = "00"
        smin = "00"
        sec = s % 60
        ssec = sec<10? '0'+sec: sec
        // str = s % 60 + 's'
        str = shour+":"+smin+":"+ssec
    }
    return str
}

/*获取某月有多少天*/
export function getMonthOfDay(time) {
    var date = new Date(time)
    var year = date.getFullYear()
    var mouth = date.getMonth() + 1
    var days

    //当月份为二月时，根据闰年还是非闰年判断天数
    if (mouth == 2) {
        days = (year % 4 == 0 && year % 100 == 0 && year % 400 == 0) || (year % 4 == 0 && year % 100 != 0) ? 28 : 29
    } else if (mouth == 1 || mouth == 3 || mouth == 5 || mouth == 7 || mouth == 8 || mouth == 10 || mouth == 12) {
        //月份为：1,3,5,7,8,10,12 时，为大月.则天数为31；
        days = 31
    } else {
        //其他月份，天数为：30.
        days = 30
    }
    return days
}

/*获取某年有多少天*/
export function getYearOfDay(time) {
    var firstDayYear = this.getFirstDayOfYear(time);
    var lastDayYear = this.getLastDayOfYear(time);
    var numSecond = (new Date(lastDayYear).getTime() - new Date(firstDayYear).getTime()) / 1000;
    return Math.ceil(numSecond / (24 * 3600));
}

/*获取某年的第一天*/
export function getFirstDayOfYear(time) {
    var year = new Date(time).getFullYear();
    return year + "-01-01 00:00:00";
}

/*获取某年最后一天*/
export function getLastDayOfYear(time) {
    var year = new Date(time).getFullYear();
    var dateString = year + "-12-01 00:00:00";
    var endDay = this.getMonthOfDay(dateString);
    return year + "-12-" + endDay + " 23:59:59";
}

/*获取某个日期是当年中的第几天*/
export function getDayOfYear(time) {
    var firstDayYear = this.getFirstDayOfYear(time);
    var numSecond = (new Date(time).getTime() - new Date(firstDayYear).getTime()) / 1000;
    return Math.ceil(numSecond / (24 * 3600));
}

/*获取某个日期在这一年的第几周*/
export function getDayOfYearWeek(time) {
    var numdays = this.getDayOfYear(time);
    return Math.ceil(numdays / 7);
}

// dateInput 是一个 js 的 Date 对象, 输出 YYYY-mm-DD HH:MM:SS
export function jsDateFormatter( dateInput ) {  
    var year = dateInput.getFullYear();
    var month = dateInput.getMonth() + 1;
    var theDate = dateInput.getDate();

    var hour = dateInput.getHours();
    var minute = dateInput.getMinutes();
    var second = dateInput.getSeconds();

    if ( month < 10 ) {undefined
        month = '0' + month;
    }

    if ( theDate < 10 ) {undefined
        theDate = '0' + theDate;
    }

    if ( hour < 10 ) {undefined
        hour = '0' + hour;
    }

    if ( minute < 10 ) {undefined
        minute = '0' + minute;
    }

    if ( second < 10 ) {undefined
        second = '0' + second;
    }

    return year +"-"+ month +"-" + theDate + " "+ hour +":"+ minute +":"+ second;
}

// dateInput 是一个 js 的 Date 对象, 输出 HH:MM 格式字符串
export function jsTimeFormat(dateInput){
    let hour = dateInput.getHours();
    let minute = dateInput.getMinutes();

    if ( hour < 10 ) {
        hour = '0' + hour;
    }

    if ( minute < 10 ) {
        minute = '0' + minute;
    }

    return  hour +":"+ minute;

}

//date => "YYYY-MM-DD"
export function jsDateFormat(dateInput){
    let Y = dateInput.getFullYear()
    let M = dateInput.getMonth()+1
    let D = dateInput.getDate()
    return Y+(M<10?"-0":"-")+M+(D<10?"-0":"-")+D
}

export function chineseNum(weekDay){
    switch(weekDay){
        case 1:
            return "一";
        case 2:
            return "二";
        case 3:
            return "三";
        case 4:
            return "四";
        case 5:
            return "五";
        case 6:
            return "六";
        case 0:
            return "日";
        default:
            return "";
    }
}

//根据当前日期，返回当周一盒当周日的日期格式的数组：
/**
 * [
                {
                    weekDay:1,
                    label:"12-13(周一)",
                    workDate:"2021-12-13"
                },
                {
                    weekDay:2,
                    label:"12-14(周二)",
                    workDate:"2021-12-14"
                },
                {
                    weekDay:3,
                    label:"12-15(周三)",
                    workDate:"2021-12-15"
                },
                {
                    weekDay:4,
                    label:"12-16(周四)",
                    workDate:"2021-12-16"
                },
                {
                    weekDay:5,
                    label:"12-17(周五)",
                    workDate:"2021-12-17"
                },
                {
                    weekDay:6,
                    label:"12-18(周六)",
                    workDate:"2021-12-18"
                },
                {
                    weekDay:7,
                    label:"12-19(周日)",
                    workDate:"2021-12-19"
                },
            ]
 */
export function jsCurrentWeekdays(dateInput){
    let weekDates =[];
    let today = new Date(dateInput);
    let weekDay = today.getDay();
    if(weekDay === 0){ //today is Sunday
        weekDay = 7
    }
    let deltaDaysToMonday = weekDay-1;
    let monday = new Date(today);
    monday.setDate(today.getDate()-deltaDaysToMonday);

    let sunday = new Date(monday);
    sunday.setDate(monday.getDate()+6);

    let i = 1;
    for(let i=1, d=monday; i<=7; i++){
        let workDate = jsDateFormat(d)
        let label = workDate.slice(5,10)+"(周"+chineseNum(i)+")"
        weekDates.push({
            weekDay:i,
            workDate:workDate,
            label: label
        })
        d.setDate(d.getDate()+1)
    }
    return weekDates;
}
