<template>
  <div class="detail">
    <!-- <div class="detail" v-loading.fullscreen.lock="true" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.3)"> -->

    <Header></Header>

    <div class="content">
      <div class="left">
        <div class="search_box">
          <el-input v-model="searchVal" placeholder="请输入商品名称/商品编码" @keydown.enter.native="searchEnterFun"></el-input>
          <el-button @click="search">查询</el-button>
        </div>
        <div class="RPrice">
          <div class="RPrice_title">
            <h3>建议参考价</h3>
            <el-select v-model="rprice" placeholder="请选择" @change="rpriceChange">
              <el-option v-for="item in timeOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="priceCk">
            <span>￥</span>
            {{priceck}}
          </div>
        </div>
        <div class="contFigure">
          <h3>商城对比图</h3>
          <div id="myCharts"></div>
        </div>
      </div>
      <div class="right">
        <h3>数据来源</h3>
        <div class="shopList">
          <div class="title">筛选商城</div>
          <div class="shopCheck">
            <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
              <el-checkbox v-for="item in cities" :label="item.value" :key="item.value">{{item.label}}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="shopInformation">
          <div class="title">
            <h3>商品信息：</h3>
            <p>{{RsearchVal}}</p>
          </div>
          <div class="price priceAvg">
            <h4><span>￥</span>{{priceAvg}}</h4>
            <p>全网平均价</p>
          </div>
          <div class="price priceMax">
            <h4><span>￥</span>{{priceMax}}</h4>
            <p>全网最高价</p>
          </div>
          <div class="price priceMin">
            <h4><span>￥</span>{{priceMin}}</h4>
            <p>全网最低价</p>
          </div>
        </div>

        <div class="goodsList">
          <div v-for="(item,i) in goodsList" :key="i" class="goodsDetails">
            <img :src="item.icon" class="goodShopLogo">
            <div class="goodsInformation" @click="toMallDetail(item.siteid,item.sitename.split('(')[0])">
              <p class="title" :title="item.goodsname">{{item.goodsname}}</p>
              <p class="coding">商品编码：</p>
              <p class="specification">规格：</p>
              <p class="sitename">{{item.sitename.split('(')[0]}}</p>
            </div>
            <div class="goodsPrice">
              <p class="priceAvg">
                <img src="@/assets/img/price_icon1.png">
                <span class="title">平均价￥</span>
                <span>{{item.priceavg}}</span>
              </p>
              <p class="priceMax">
                <img src="@/assets/img/price_icon2.png">
                <span class="title">最高价￥</span>
                <span>{{item.priceMax}}</span>
              </p>
              <p class="priceMin">
                <img src="@/assets/img/price_icon3.png">
                <span class="title">最低价￥</span>
                <span>{{item.pricemin}}</span>
              </p>
            </div>
            <div class="goodsPicBox">
              <img :src="item.pic" class="goodsPic">
              <p class="goodsPicTitle">商品图</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="progress" v-if="loading">
      <div id="loading">
        <div id="loading-center">
          <div id="loading-center-absolute">
            <div class="object"></div>
            <div class="object"></div>
            <div class="object"></div>
            <div class="object"></div>
            <div class="object"></div>
            <div class="object"></div>
            <div class="object"></div>
            <div class="object"></div>
            <div class="object"></div>
            <div class="object"></div>
          </div>
          <div id="loading-text">
            <p>数据全网检索中，请稍后...</p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { shoplist, aso } from "@/api/getData";
import * as echarts from "echarts";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: { Header, Footer },
  name: "Detail",
  data() {
    return {
      loading: false,
      searchVal: '',
      RsearchVal: '',
      rprice: '1',
      timeOptions: [{
        value: '1',
        label: '近一日'
      }, {
        value: '7',
        label: '近一周'
      }, {
        value: '30',
        label: '近一个月'
      }],
      priceck: '',
      checkedCities: ["s_3","s_83","s_370","s_39"],
      cities: [],
      priceAvg: '',
      priceMax: '',
      priceMin: '',
      goodsList: []
    }
  },
  methods: {
    searchEnterFun(e) {
      var keyCode = window.event ? e.keyCode : e.which;
      //Enter键的代码就是13
      if (e.keyCode == 13) {
        this.search()
      }
    },
    //echarts
    echrtsInit(data) {
      var chartDom = document.getElementById('myCharts');
      var myChart = echarts.init(chartDom);
      var option;

      let xData = [];
      let ordersNums = [];
      let sum = 0;
      data.map((item) => {
        xData.push(item.sitename.split('(')[0]);
        ordersNums.push(item.priceavg);
      });
      for (let i = 0; i < ordersNums.length; i++) {
        sum += ordersNums[i]
      }

      option = {
        color: ['#00EAFF'],
        //鼠标移动上去显示
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        xAxis: {
          type: 'category',
          data: xData,
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: "#00EAFF"
            }
          },
          axisLabel: {
            color: "#fff",
            margin: 20,
            rotate: "45"
          }
        },
        yAxis: {
          name: "价格(元)",
          type: 'value',
          nameTextStyle: {
            align: "right",
            color: "#fff"
          },
          nameGap: 30,
          splitNumber: 4,
          axisLine: {
            show: true
          },
          axisLabel: {
            color: "#fff"
          },
          //分割线
          splitLine: {
            lineStyle: {
              color: "#043438",
              type: "dashed"
            }
          },
        },
        series: [
          {
            data: ordersNums,
            type: 'bar',
            barWidth: 16,//柱图宽度
            label: {
              show: true,
              color: "#00EAFF",
              position: "top"
            },
            //平均线
            // markLine: {
            //   data: [
            //     { type: 'average', name: '平均值' }
            //   ],
            //   symbol: ['none', 'none'],
            //   position: "insideTopCenter",
            //   itemStyle: {
            //     normal: {
            //       lineStyle: {
            //         type: 'dotted',
            //         color: 'red'
            //       },
            //       label: {
            //         show: true,
            //         position: 'middle',
            //         formatter: "数据平均 : " + (sum / ordersNums.length).toFixed(2),
            //       }
            //     }
            //   },
            //   large: true,
            //   effect: {
            //     show: false,
            //     loop: true,
            //     period: 0,
            //     scaleSize: 2,
            //     color: null,
            //     shadowColor: null,
            //     shadowBlur: null
            //   },
            // }
          }
        ]
      };

      myChart.setOption(option);
    },
    //获取商城列表
    getShoplist() {
      var shoplistData = new FormData();
      shoplistData.append('token', this.gToken);
      shoplist(shoplistData).then((res) => {
        //res.result.unshift('0|全部')
        this.cities = res.result.map(item => ({
          value: item.split('|')[0],
          label: item.split('|')[1]
        }))
      })
    },
    //去重
    unique(arr) {
      return Array.from(new Set(arr))
    },
    //搜索结果
    getAso() {
      this.loading = true
      var asoData = new FormData();
      asoData.append('day', this.rprice);
      asoData.append('goodskey', this.searchVal);
      asoData.append('sitename', this.checkedCities);
      asoData.append('token', this.gToken);
      aso(asoData).then((res) => {
        this.loading = false
        this.RsearchVal = this.searchVal
        //其他信息
        this.priceck = res.result.priceck
        this.priceAvg = res.result.priceavg
        this.priceMax = res.result.priceMax
        this.priceMin = res.result.pricemin
        //商品列表
        this.goodsList = res.result.pricelist
        this.goodsList.map(item => {
          switch (item.siteid) {
            case 's_3':
              item.icon = require("@/assets/img/icon_jd.png");
              break;
            case 's_1':
              item.icon = require("@/assets/img/icon_ymx.png");
              break;
            case 's_25':
              item.icon = require("@/assets/img/icon_snyg.png");
              break;
            case 's_83':
              item.icon = require("@/assets/img/icon_tb.png");
              break;
            case 's_129':
              item.icon = require("@/assets/img/icon_wph.png");
              break;
            case 's_370':
              item.icon = require("@/assets/img/icon_pdd.png");
              break;
            case 's_39':
              item.icon = require("@/assets/img/icon_xmyp.png");
              break;
            case 's_223':
              item.icon = require("@/assets/img/icon_wykl.png");
              break;
            case 's_2':
              item.icon = require("@/assets/img/icon_dd.png");
              break;
          }
        })
        //echearts
        this.echrtsInit(res.result.pricelist)

        console.log(res.result.pricelist)
        let newArr = []
        for (var i = 0; i < res.result.pricelist.length; i++) {
          newArr.push(res.result.pricelist[i].siteid)
        }
        this.checkedCities = this.unique(newArr)
      }).catch(() => {
        this.loading = false
      });
    },
    //搜索
    search() {
      this.$router.push({
        path: '/detail',
        query: {
          selected: this.searchVal
        }
      });
      this.getAso()
    },
    //选择时间段
    rpriceChange(val) {
      this.rprice = val;
      this.getAso()
    },
    //多选
    handleCheckedCitiesChange() {
      this.getAso()
    },
    //跳转详情页
    toMallDetail(siteId, sitename) {
      let routeUrl = this.$router.resolve({
        path: '/mall-detail',
        query: {
          goodskey: this.searchVal,
          siteId: siteId,
          sitename: sitename
        }
      });
      window.open(routeUrl.href, '_blank');
    }
  },
  mounted() {
    this.searchVal = this.$route.query.selected
    this.getShoplist()
    this.getAso()
  }
}
</script>

<style lang="scss" scoped>
.goodsList::-webkit-scrollbar {
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.goodsList::-webkit-scrollbar-thumb {
  background: #02b7dc;
}
.detail {
  background: url(../../assets/img/index_bg.png) no-repeat center;
  height: 10.8rem;
  position: relative;

  .content {
    padding: 0.56rem 1.4rem 1rem;
    display: flex;
    justify-content: space-between;
    .left {
      width: 43.08%;
      max-width: 7.06rem;
      .search_box {
        width: 100%;
        height: 0.75rem;
        background: #ffffff;
        border-radius: 6px;
        display: flex;
        ::v-deep .el-input__inner {
          height: 100%;
          border: none;
          font-size: 0.22rem;
          padding-left: 0.4rem;
        }
        ::v-deep .el-button {
          width: 2rem;
          height: 0.69rem;
          background: linear-gradient(43deg, #1ccdf4, #00feca);
          font-size: 0.22rem;
          margin: 0.03rem;
        }
      }
      .RPrice {
        height: 2.81rem;
        background: rgba(3, 14, 30, 0.8);
        border: 1px solid #00eaff;
        border-radius: 0.06rem;
        margin-top: 0.22rem;
        padding: 0.16rem 0.24rem 0;
        box-sizing: border-box;
        .RPrice_title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          ::v-deep .el-input__inner {
            width: 1.64rem;
            height: 0.38rem;
            line-height: 0.38rem;
            border: 1px solid #14d9e8;
            background: none;
            color: #fff;
          }
          ::v-deep .el-select__caret {
            line-height: 0.38rem;
          }
        }
        .priceCk {
          font-size: 0.76rem;
          color: #00eaff;
          font-weight: bold;
          text-align: center;
          border-bottom: 1px solid #074247;
          width: 3.32rem;
          padding-bottom: 0.1rem;
          margin: 0.6rem auto 0;
          span {
            font-size: 0.3rem;
            color: #fff;
          }
        }
      }
      .contFigure {
        height: 4.17rem;
        background: rgba(3, 14, 30, 0.8);
        border: 1px solid #00eaff;
        border-radius: 0.06rem;
        margin-top: 0.2rem;
        padding: 0.24rem;
        box-sizing: border-box;
        #myCharts {
          height: 3.67rem;
          margin-top: 0.1rem;
        }
      }
    }
    .right {
      width: 55.73%;
      max-width: 9.14rem;
      height: 8.13rem;
      background: rgba(3, 14, 30, 0.8);
      border: 1px solid #00eaff;
      border-radius: 0.06rem;
      padding: 0.24rem;
      box-sizing: border-box;
      .shopList {
        background: #171f3e;
        height: 0.8rem;
        margin-top: 0.22rem;
        display: flex;
        align-items: center;
        .title {
          font-size: 0.14rem;
          line-height: 0.2rem;
          color: #00eaff;
          width: 0.32rem;
          padding: 0 0.19rem;
          border-right: 1px solid #323b5e;
        }
        .shopCheck {
          width: 7.7rem;
          padding-left: 0.26rem;
          margin-top: 0.04rem;
          ::v-deep .el-checkbox {
            margin-bottom: 0.04rem;
            margin-right: 0;
            min-width: 1.4rem;
            .el-checkbox__label {
              color: #fff;
            }
          }
        }
      }
      .shopInformation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        padding: 0.2rem 0.4rem 0.3rem 0;
        border-bottom: 1px solid #074247;
        .title {
          h3 {
            font-size: 0.14rem;
          }
          p {
            font-size: 0.18rem;
            margin-top: 0.18rem;
            font-weight: bold;
          }
        }
        .price {
          text-align: center;
          h4 {
            font-size: 0.22rem;
            span {
              color: #fff;
              font-size: 0.14rem;
              margin-right: 0.08rem;
            }
          }
          p {
            width: 0.94rem;
            height: 0.24rem;
            line-height: 0.24rem;
            background: #171f3e;
            border-radius: 0.12rem;
            font-size: 0.14rem;
            margin-top: 0.08rem;
          }
        }
        .priceAvg {
          color: #ffa22f;
        }
        .priceMax {
          color: #f21d1d;
        }
        .priceMin {
          color: #20ca68;
        }
      }
      .goodsList {
        max-height: 5.5rem;
        overflow-y: auto;
        .goodsDetails {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-top: 0.18rem;
          .goodShopLogo {
            display: block;
            width: 0.9rem;
            height: 0.9rem;
          }
          .goodsInformation {
            color: #fff;
            margin-left: 0.18rem;
            width: 3.6rem;
            cursor: pointer;
            padding-right: 0.2rem;
            p {
              font-size: 0.14rem;
              margin-bottom: 0.07rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .title {
              font-size: 0.18rem;
              font-weight: bold;
            }
            .sitename {
              color: #00eaff;
              font-size: 0.12rem;
              width: 0.7rem;
              text-align: center;
              border: 1px solid #00eaff;
              border-radius: 0.1rem;
              margin-top: 0.1rem;
              padding: 0.04rem 0.1rem;
            }
          }
          .goodsPrice {
            color: #fff;
            margin-left: auto;
            p {
              margin-bottom: 0.2rem;
              font-size: 0.18rem;
              img,
              span {
                vertical-align: middle;
              }
              img {
                margin-right: 0.1rem;
                width: 0.24rem;
              }
              .title {
                margin-right: 0.1rem;
                font-size: 0.14rem;
                color: #fff;
              }
            }
            .priceAvg {
              color: #ffa22f;
            }
            .priceMax {
              color: #f21d1d;
            }
            .priceMin {
              color: #1ebd62;
            }
          }
          .goodsPicBox {
            margin-left: auto;
            margin-right: 0.2rem;
            .goodsPic {
              width: 0.68rem;
              height: 0.68rem;
              border-radius: 0.04rem;
            }
            .goodsPicTitle {
              font-size: 0.14rem;
              color: #fff;
              text-align: center;
              margin-top: 0.06rem;
            }
          }
        }
      }
    }
  }
  h3 {
    font-size: 0.22rem;
    color: #00eaff;
  }
}
</style>