import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import router from "@/router"

const service = axios.create({
	baseURL: '/wtapi',
	timeout: 50000
})

//http request拦截
service.interceptors.request.use(req => {
	if (store.state.gToken) {
		req.headers['x-token'] = store.state.gToken
	}
	return req
})

//http response拦截
service.interceptors.response.use(res => {
	if (res.status === 200) {
		const message = res.data.message || '未知错误'
		if (res.data.code === 1 && (res.data.message === '无效用户' || res.data.message === '请登录')) {
			store.commit('Logout');
			router.replace("/login");
		} else if(res.data.code !== 200){
			Message({
				message: message,
				type: 'error'
			});
			return Promise.reject(new Error(message))
		}
	}
	return res.data
})

export default service