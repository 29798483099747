<template>
  <div class="detail">
    <Header></Header>
    <div class="content">
      <div class="left">
        <div class="search_box">
          <p class="source"><img :src="mallIcon" class="mallIcon">{{source}}来源</p>
          <p class="goodsName">{{searchVal}}</p>
        </div>
        <div class="RPrice">
          <div class="RPrice_title">
            <h3>历史价格</h3>
            <el-select v-model="rprice" placeholder="请选择" @change="rpriceChange">
              <el-option v-for="item in timeOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="RPrice_cont">
            <div class="price priceMin">
              <h4><span>￥</span>{{priceMin}}</h4>
              <p>全网最低价</p>
            </div>
            <div class="price priceAvg">
              <h4><span>￥</span>{{priceAvg}}</h4>
              <p>全网平均价</p>
            </div>
            <div class="price priceMax">
              <h4><span>￥</span>{{priceMax}}</h4>
              <p>全网最高价</p>
            </div>
          </div>
        </div>
        <div class="contFigure">
          <div class="contFigure_title">
            <h3>价格走势图</h3>
            <!-- <el-select v-model="eTime" placeholder="请选择" @change="cpriceChange">
              <el-option v-for="item in eTimeOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select> -->
          </div>
          <div id="myCharts"></div>
        </div>
      </div>
      <div class="right">
        <h3>商品</h3>
        <div class="screeningBox">
          <div class="screening">
            <el-radio-group v-model="orderBy" @change="orderChange">
              <el-radio-button label="0">默认排序</el-radio-button>
              <el-radio-button label="1">升序<i class="el-icon-top"></i></el-radio-button>
              <el-radio-button label="2">降序<i class="el-icon-bottom"></i></el-radio-button>
            </el-radio-group>
          </div>
          <div class="priceRange">
            <p class="title">价格</p>
            <el-input v-model="spriceMin" placeholder="最低价"></el-input>
            <p class="line">—</p>
            <el-input v-model="spriceMax" placeholder="最高价"></el-input>
          </div>
          <el-button class="screenBtn" @click="screenSearch">确定</el-button>
        </div>

        <ul class="goodsBox">
          <li class="goodsList" v-for="(item,i) in goodsList" :key="i">
            <img :src="item.pic" class="goodShopLogo">
            <div class="goodsInformation">
              <p class="title" :title="item.goodsname">{{item.goodsname}}</p>
              <p class="coding">原产地：</p>
              <p class="specification">规格：</p>
              <p class="sitename">{{item.sitename}}</p>
            </div>
            <div class="goodsRight">
              <p class="goodsPrice"><span>￥</span>{{item.price}}</p>
              <p class="goodsBtn" @click="toLink(item.url)">直达链接 ></p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    
    <div id="progress" v-if="loading">
      <div id="loading">
        <div id="loading-center">
          <div id="loading-center-absolute">
            <div class="object"></div>
            <div class="object"></div>
            <div class="object"></div>
            <div class="object"></div>
            <div class="object"></div>
            <div class="object"></div>
            <div class="object"></div>
            <div class="object"></div>
            <div class="object"></div>
            <div class="object"></div>
          </div>
          <div id="loading-text">
            <p>数据全网检索中，请稍后...</p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { siteso } from "@/api/getData";
import * as echarts from "echarts";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: { Header, Footer },
  name: "MallDetail",
  data() {
    return {
      loading: false,
      searchVal: '',
      rprice: '1',
      timeOptions: [{
        value: '1',
        label: '近一日'
      }, {
        value: '7',
        label: '近七日'
      }, {
        value: '30',
        label: '近一个月'
      }],
      source: '',
      priceAvg: '',
      priceMax: '',
      priceMin: '',
      eTime: '7',
      eTimeOptions: [{
        value: '1',
        label: '近一日'
      }, {
        value: '7',
        label: '近一周'
      }],
      orderBy: '0',
      spriceMin: '',
      spriceMax: '',
      goodsList: [],
      siteId: '',
      mallIcon: ''
    }
  },
  methods: {
    //手动搜索
    screenSearch() {
      // if (this.spriceMin && this.spriceMax) {
      this.getSiteso()
      // } else {
      //   this.$message.error('请输入最低价和最高价');
      // }
    },
    //排序
    orderChange() {
      console.log(this.orderBy)
      this.getSiteso()
    },
    //echarts
    echrtsInit(data) {
      console.log(data)
      var chartDom = document.getElementById('myCharts');
      var myChart = echarts.init(chartDom);
      var option;

      let xData = [];
      let ordersNums = [];
      let sum = 0;
      data.map((item) => {
        xData.push(item.pricedate.slice(5, 10));
        ordersNums.push(item.price);
      });
      for (let i = 0; i < ordersNums.length; i++) {
        sum += ordersNums[i]
      }

      option = {
        color: ['#00EAFF'],
        //鼠标移动上去显示
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        xAxis: {
          type: 'category',
          data: xData,
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: "#00EAFF"
            }
          },
          axisLabel: {
            color: "#fff",
            margin: 20,
            rotate: "45"
          }
        },
        yAxis: {
          name: "价格(元)",
          type: 'value',
          nameTextStyle: {
            align: "right",
            color: "#fff"
          },
          nameGap: 30,
          splitNumber: 4,
          axisLine: {
            show: true
          },
          axisLabel: {
            color: "#fff"
          },
          //分割线
          splitLine: {
            lineStyle: {
              color: "#043438",
              type: "dashed"
            }
          },
        },
        series: [
          {
            data: ordersNums,
            type: 'line',
            barWidth: 16,//柱图宽度
            label: {
              show: true,
              color: "#00EAFF",
              position: "top"
            },
            //平均线
            markLine: {
              data: [
                { type: 'average', name: '平均值' }
              ],
              symbol: ['none', 'none'],
              position: "insideTopCenter",
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: 'dotted',
                    color: '#FFA22F'
                  },
                  label: {
                    show: true,
                    position: 'end',
                    distance: 0,
                    color: '#FFA22F',
                    formatter: "平均价 : \n\n" + (sum / ordersNums.length).toFixed(2),
                  }
                }
              },
              large: true,
              effect: {
                show: false,
                loop: true,
                period: 0,
                scaleSize: 2,
                color: null,
                shadowColor: null,
                shadowBlur: null
              },
            }
          }
        ]
      };

      myChart.setOption(option);
    },
    //外链
    toLink(url) {
      window.open(url);
    },
    //选择时间段
    rpriceChange(val) {
      this.rprice = val;
      this.getSiteso()
    },
    //价格走势时间段
    cpriceChange(val) {
      this.eTime = val;
      this.getSiteso()
    },
    //获取搜索结果
    getSiteso() {
      this.loading = true
      var asoData = new FormData();
      asoData.append('day', this.rprice);
      asoData.append('historyDay', this.rprice);
      asoData.append('goodskey', this.searchVal);
      asoData.append('sitename', this.source);
      asoData.append('siteId', this.siteId);
      asoData.append('orderby', this.orderBy);
      asoData.append('pricemin', this.spriceMin);
      asoData.append('priceMax', this.spriceMax);
      asoData.append('token', this.gToken);
      siteso(asoData).then((res) => {
        this.loading = false
        //其他信息
        this.priceAvg = res.result.priceavg
        this.priceMax = res.result.priceMax
        this.priceMin = res.result.pricemin
        //商品列表
        this.goodsList = res.result.shoplist
        this.echrtsInit(res.result.historyprice)
      })
    }
  },
  mounted() {
    this.searchVal = this.$route.query.goodskey
    this.source = this.$route.query.sitename
    this.siteId = this.$route.query.siteId
    this.getSiteso()
    switch (this.$route.query.siteId) {
      case '1':
        this.mallIcon = require("@/assets/img/icon_jd.png");
        break;
      case '4':
        this.mallIcon = require("@/assets/img/icon_ymx.png");
        break;
      case '6':
        this.mallIcon = require("@/assets/img/icon_snyg.png");
        break;
      case '10':
        this.mallIcon = require("@/assets/img/icon_tm.png");
        break;
      case '15':
        this.mallIcon = require("@/assets/img/icon_tb.png");
        break;
      case '36':
        this.mallIcon = require("@/assets/img/icon_wph.png");
        break;
      case '42':
        this.mallIcon = require("@/assets/img/icon_zlwmw.png");
        break;
      case '241':
        this.mallIcon = require("@/assets/img/icon_wykl.png");
        break;
      case '306':
        this.mallIcon = require("@/assets/img/icon_wyyx.png");
        break;
      case '307':
        this.mallIcon = require("@/assets/img/icon_pdd.png");
        break;
      case '308':
        this.mallIcon = require("@/assets/img/icon_xhs.png");
        break;
    }
  }
}
</script>

<style lang="scss" scoped>
.goodsBox::-webkit-scrollbar {
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.goodsBox::-webkit-scrollbar-thumb {
  background: #02b7dc;
}
.detail {
  background: url(../../assets/img/index_bg.png) no-repeat center;
  height: 10.8rem;
  position: relative;
  .content {
    padding: 0.56rem 1.4rem 1rem;
    display: flex;
    justify-content: space-between;
    .left {
      width: 43.08%;
      max-width: 7.06rem;
      .search_box {
        height: 0.75rem;
        border-radius: 6px;
        display: flex;
        background: rgba(3, 14, 30, 0.8);
        border: 1px solid #00eaff;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.25rem;
        .source {
          font-size: 0.22rem;
          font-weight: bold;
          color: #00eaff;
          .mallIcon {
            width: 0.22rem;
            vertical-align: bottom;
            margin-right: 0.1rem;
          }
        }
        .goodsName {
          font-size: 0.22rem;
          color: #fff;
        }
      }
      .RPrice {
        height: 2.81rem;
        background: rgba(3, 14, 30, 0.8);
        border: 1px solid #00eaff;
        border-radius: 0.06rem;
        margin-top: 0.22rem;
        padding: 0.16rem 0.24rem 0;
        box-sizing: border-box;
        .RPrice_title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          ::v-deep .el-input__inner {
            width: 1.64rem;
            height: 0.38rem;
            line-height: 0.38rem;
            border: 1px solid #14d9e8;
            background: none;
            color: #fff;
          }
          ::v-deep .el-select__caret{
            line-height: 0.38rem;
          }
        }
        .RPrice_cont {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          padding: 0.7rem 0.3rem 0;
          .price {
            text-align: center;
            h4 {
              font-size: 0.3rem;
              span {
                color: #fff;
                font-size: 0.16rem;
                margin-right: 0.08rem;
              }
            }
            p {
              width: 1.14rem;
              height: 0.34rem;
              line-height: 0.34rem;
              background: #171f3e;
              border-radius: 0.17rem;
              font-size: 0.18rem;
              margin-top: 0.15rem;
            }
          }
          .priceAvg {
            h4 {
              font-size: 0.48rem;
            }
          }
          .priceAvg {
            color: #ffa22f;
          }
          .priceMax {
            color: #f21d1d;
          }
          .priceMin {
            color: #20ca68;
          }
        }
      }
      .contFigure {
        height: 4.17rem;
        background: rgba(3, 14, 30, 0.8);
        border: 1px solid #00eaff;
        border-radius: 0.06rem;
        margin-top: 0.2rem;
        padding: 0.24rem;
        box-sizing: border-box;
        .contFigure_title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          ::v-deep .el-input__inner {
            width: 1.64rem;
            height: 0.38rem;
            line-height: 0.38rem;
            border: 1px solid #14d9e8;
            background: none;
            color: #fff;
          }
          ::v-deep .el-select__caret{
            line-height: 0.38rem;
          }
        }
        #myCharts {
          height: 3.5rem;
          margin-top: 0.1rem;
        }
      }
    }
    .right {
      width: 55.73%;
      max-width: 9.14rem;
      height: 8.13rem;
      background: rgba(3, 14, 30, 0.8);
      border: 1px solid #00eaff;
      border-radius: 0.06rem;
      padding: 0.24rem;
      box-sizing: border-box;
      .screeningBox {
        display: flex;
        padding: 0.25rem 0;
        border-bottom: 1px solid #074247;
        .screening {
          width: 3.5rem;
          ::v-deep .el-radio-button__inner {
            width: 1.05rem;
            height: 0.34rem;
            padding: 0;
            line-height: 0.32rem;
            font-size: 0.14rem;
            color: #fff;
            background: none;
            border-color: #00eaff;
            i {
              font-size: 0.14rem;
              margin-left: 4px;
            }
          }
          ::v-deep
            .el-radio-button__orig-radio:checked
            + .el-radio-button__inner {
            background: #00eaff;
            color: #000;
          }
        }
        .priceRange {
          display: flex;
          color: #fff;
          font-size: 0.14rem;
          line-height: 0.34rem;
          .el-input {
            width: 1.05rem;
          }
          ::v-deep .el-input__inner {
            width: 1.05rem;
            height: 0.34rem;
            line-height: 0.34rem;
            border-radius: 0;
            background: none;
            border: 1px solid #14d9e8;
          }
          .title {
            margin-right: 0.12rem;
          }
          .line {
            margin: 0 0.07rem;
          }
        }
        .screenBtn {
          margin-left: auto;
          width: 1.05rem;
          height: 0.34rem;
          background: linear-gradient(43deg, #1ccdf4, #00feca);
          border-radius: 0.04rem;
          border: none;
          padding: 0;
          font-size: 0.14rem;
          color: #000;
        }
      }
      .goodsBox {
        max-height: 6.6rem;
        overflow-y: auto;
        padding-right: 0.2rem;
        .goodsList {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-top: 0.48rem;
          .goodShopLogo {
            display: block;
            width: 0.9rem;
            height: 0.9rem;
          }
          .goodsInformation {
            color: #fff;
            margin-left: 0.18rem;
            width: 5rem;
            padding-right: 0.2rem;
            p {
              font-size: 0.14rem;
              margin-bottom: 0.07rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .title {
              font-size: 0.18rem;
              font-weight: bold;
            }
            .sitename {
              color: #00eaff;
              font-size: 0.12rem;
              width: 1rem;
              text-align: center;
              border: 1px solid #00eaff;
              border-radius: 0.1rem;
              margin-top: 0.1rem;
              padding: 0.04rem 0.1rem;
            }
          }
          .goodsRight {
            margin-left: auto;
            .goodsPrice {
              color: #14d9e8;
              font-size: 0.24rem;
              font-weight: bold;
              text-align: right;
              span {
                color: #fff;
                font-size: 0.14rem;
                margin-right: 0.08rem;
              }
            }
            .goodsBtn {
              width: 1.26rem;
              height: 0.34rem;
              background: linear-gradient(43deg, #1ccdf4, #00feca);
              border-radius: 0.17rem;
              font-size: 0.14rem;
              line-height: 0.34rem;
              text-align: center;
              margin-top: 0.34rem;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  h3 {
    font-size: 0.22rem;
    color: #00eaff;
  }
}
</style>