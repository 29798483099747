<template>
  <div class="footer">
      <p>© CopyRight 2019 北京澎湃数据服务有限公司 京ICP备2024046608号-2</p>
      <p>为了获得更好的用户体验，请使用最新版谷歌浏览器。</p>
  </div>

</template>


<script>

export default {
  name: "Header",
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.footer{
    color: #fff;
    text-align: center;
    line-height: 1.5;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0.2rem;
}
</style>
