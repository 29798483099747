import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store'
import Home from "../views/Home/Home";
import Login from '../views/Login/Login.vue'
import Detail from '../views/Detail/Detail.vue'
import MallDetail from '../views/MallDetail/MallDetail.vue'

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/login',
		name: 'Login',
		component: Login
	},
	{
		path: '/detail',
		name: 'Detail',
		meta: {
            requireAuth: true,  
        },
		component: Detail
	},
	{
		path: '/mall-detail',
		name: 'MallDetail',
		meta: {
            requireAuth: true,  
        },
		component: MallDetail
	}
];

const router = new VueRouter({
	routes
});

// 页面打开之前
router.beforeEach((to, from, next) => {
	// console.log(`to requireAuth=${to.meta.requireAuth}, gToken=${store.state.gToken}`)
	if(to.meta.requireAuth){
		if(store.state.gToken){
			next()
		}else{
			next('/')
		}
	}else{
		next()
	}
});

//router path重复异常处理
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
};

export default router;