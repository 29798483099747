import axios from "../request"

//登录
export const login = (data) => axios.post('/globalgoodsprice/login',data)
//最近搜索热词
export const sokeylist = (data) => axios.post('/globalgoodsprice/sokeylist',data)
//商城列表
export const shoplist = (data) => axios.post('/globalgoodsprice/shoplist',data)
//多条件搜索
export const aso = (data) => axios.post('/globalgoodsprice/aso',data)
//详情搜索
export const siteso = (data) => axios.post('/globalgoodsprice/siteso',data)


