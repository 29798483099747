<template>
  <div class="header">
    <img src="@/assets/img/logo.png" class="logo" @click="toIndex">
    <img src="@/assets/img/detail_title.png" class="title">
    <div class="userName" v-if="gToken">
      欢迎：{{gName}}
      <img src="@/assets/img/logout_icon.png" @click="logout">
    </div>
    <!-- <div class="userName" v-else style="cursor: pointer;" @click="toLogin">
      登录
    </div> -->
  </div>

</template>


<script>

export default {
  name: "Header",
  data() {
    return {
    };
  },
  methods: {
    //登出
    logout() {
      this.$confirm('是否退出', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.commit("Logout");
        this.$router.replace("/login");
      }).catch(() => {
      });
    },
    toIndex() {
      this.$router.replace("/");
    }
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  color: #fff;
  align-items: center;
  padding: 0 1.4rem;
  height: 0.9rem;
  box-sizing: border-box;
  border-bottom: 1px solid #00eaff;

  .logo {
    width: 1.68rem;
      cursor: pointer;
  }
  .title {
    width: 5.39rem;
  }
  .userName {
    img {
      margin-left: 0.28rem;
      cursor: pointer;
    }
  }
}
</style>
