import {
    mapGetters
} from "vuex";

export const mixin = {

    data() {
        return {

        }
    },
    computed: {
        ...mapGetters(['gToken','gRoleName', 'gAccount', 'gName','gAccountId', 'gOrgId']),
    },
    methods: {
        
    }
}