<template>
  <div class="home">
    <div class="header">
      <img src="@/assets/img/logo.png" class="logo" @click="toIndex">
      <div class="userName" v-if="gToken">
        欢迎：{{gName}}
        <img src="@/assets/img/logout_icon.png" @click="logout">
      </div>
      <div class="userName" v-else style="cursor: pointer;" @click="toLogin">
        登录
      </div>
    </div>
    <img src="@/assets/img/title.png" class="title">
    <div class="search_box">
      <el-input v-model="searchVal" placeholder="请输入商品名称/商品编码" @keydown.enter.native="searchEnterFun"></el-input>
      <el-button @click="search">查询</el-button>
    </div>
    <div class="search_records_box">
      <p class="search_records_title">最近搜索</p>
      <div class="search_records">
        <div v-for="(item,i) in searchList" :key="i" @click="hotKey(item.value)">{{item.value}}</div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { sokeylist } from "@/api/getData";
import Footer from "@/components/Footer.vue";
export default {
  components: { Footer },
  name: "Home",
  data() {
    return {
      searchVal: '',
      searchList: [
      ]
    }
  },
  mounted() {
    this.getSokeylist()
  },
  methods: {
    toIndex() {
      this.$router.replace("/");
    },
    searchEnterFun(e) {
      var keyCode = window.event ? e.keyCode : e.which;
      //Enter键的代码就是13
      if (e.keyCode == 13) {
        this.search()
      }
    },
    //热词搜索
    hotKey(val) {
      this.searchVal = val
      this.$router.push({
        path: '/detail',
        query: {
          selected: this.searchVal
        }
      });
    },
    //搜索
    search() {
      if (this.gToken) {
        if (this.searchVal) {
          this.$router.push({
            path: '/detail',
            query: {
              selected: this.searchVal
            }
          });
        } else {
          this.$message.error('请输入商品名称/商品编码');
        }
      } else {
        this.$router.push({
          path: '/login'
        });
      }
    },
    //去登录
    toLogin() {
      this.$router.replace("/login");
    },
    //登出
    logout() {
      this.$confirm('是否退出', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.commit("Logout");
        this.$router.replace("/login");
      }).catch(() => {
      });
    },
    //获取搜索热词
    getSokeylist() {
      var formData = new FormData();
      formData.append('token', this.gToken);
      formData.append('number', 6);
      sokeylist(formData).then((res) => {
        this.searchList = res.result.map(item => ({
          value: item
        }))
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.home {
  background: url(../../assets/img/index_bg.png) no-repeat center;
  height: 10.8rem;
  position: relative;

  .header {
    display: flex;
    justify-content: space-between;
    color: #fff;
    align-items: center;
    padding: 0.22rem 1.4rem;

    .logo {
      width: 1.68rem;
      cursor: pointer;
    }

    .userName {
      img {
        margin-left: 0.28rem;
        cursor: pointer;
      }
    }
  }

  .title {
    margin: 1.6rem auto 0;
    display: block;
  }

  .search_box {
    width: 7.64rem;
    height: 0.75rem;
    background: #ffffff;
    border-radius: 0.06rem;
    margin: 1.3rem auto 0;
    display: flex;

    ::v-deep .el-input__inner {
      height: 0.75rem;
      border: none;
      font-size: 0.22rem;
      padding-left: 0.4rem;
    }

    ::v-deep .el-button {
      width: 2rem;
      height: 0.69rem;
      background: linear-gradient(43deg, #1ccdf4, #00feca);
      font-size: 0.22rem;
      margin: 0.03rem;
    }
  }

  .search_records_box {
    width: 7.3rem;
    margin: 0.5rem auto 0;

    .search_records_title {
      color: #b1b1b1;
      font-size: 0.16rem;
    }

    .search_records {
      display: flex;
      flex-wrap: wrap;
      margin-left: -0.6rem;

      div {
        color: #00eaff;
        font-size: 0.16rem;
        // margin-left: 0.6rem;
        margin-top: 0.3rem;
        cursor: pointer;
        width: 33.3%;
        padding-left: 0.6rem;
        box-sizing: border-box;
      }
    }
  }
}
</style>