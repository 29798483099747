import Vue from 'vue'
import Vuex from 'vuex'
import {
  setStore,
  getStore,
  clearStore
} from '@/utils/store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    gToken: getStore({
      name: 'gToken'
    }) || '',//token
    gRoleName: getStore({
      name: 'gRoleName'
    }) || '',//admin,doctor,therapist
    gAccount: getStore({
      name: 'gAccount'
    }) || '',//login name
    gName: getStore({
      name: 'gName'
    }) || '', //display name
    gAccountId: getStore({
      name: 'gAccountId'
    }) || '', //account id
    gOrgId: getStore({
      name: 'gOrgId'
    }) || '', //机构id
  },
  mutations: {
    SET_GTOKEN: (state, gToken) => {
      state.gToken = gToken;
      setStore({
        name: 'gToken',
        content: state.gToken
      })
    },
    SET_GROLENAME: (state, gRoleName) => {
      state.gRoleName = gRoleName;
      setStore({
        name: 'gRoleName',
        content: state.gRoleName
      })
    },
    SET_GACCOUNT: (state, gAccount) => {
      state.gAccount = gAccount;
      setStore({
        name: 'gAccount',
        content: state.gAccount
      })
    },
    SET_GNAME: (state, gName) => {
      state.gName = gName;
      setStore({
        name: 'gName',
        content: state.gName
      })
    },
    SET_GACCOUNTID: (state, gAccountId) => {
      state.gAccountId = gAccountId;
      setStore({
        name: 'gAccountId',
        content: state.gAccountId
      })
    },
    SET_GORGID: (state, gOrgId) => {
      state.gOrgId = gOrgId;
      setStore({
        name: 'gOrgId',
        content: state.gOrgId
      })
    },
    Logout: (state) => {
      state.gToken = '';
      clearStore();
    },
  },
  actions: {
    // Logout() {
    //   console.log("store Logout")
    //   clearStore();
    // }
  },
  getters: {
    gToken: state => state.gToken,
    gRoleName: state => state.gRoleName,
    gAccount: state => state.gAccount,
    gName: state => state.gName,
    gAccountId: state => state.gAccountId,
    gOrgId: state => state.gOrgId,
  },
  modules: {
  }
})
