<template>
  <div class="login">
    <div class="header">
      <img src="@/assets/img/logo.png" class="logo" @click="toIndex" />
      <div class="date">
        <p class="time">{{ time }}</p>
        <p class="line"></p>
        <div>
          <p class="weekday">星期{{ weekDay }}</p>
          <p class="dateCnt">{{ date }}</p>
        </div>
        <img src="@/assets/img/full_icon.png" class="full" @click="full" />
      </div>
    </div>
    <img src="@/assets/img/title.png" class="title" />
    <div class="formBox">
      <div class="formBg">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0">
          <el-form-item prop="Loginname">
            <el-input v-model="ruleForm.Loginname" placeholder="用户名"></el-input>
          </el-form-item>
          <el-form-item prop="Passwd">
            <el-input v-model="ruleForm.Passwd" placeholder="密码" type="password"></el-input>
          </el-form-item>
          <div class="aboutPassword">
            <el-checkbox v-model="checked">记住密码</el-checkbox>
            <p @click="forgotPass">忘记密码</p>
          </div>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">登 录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { login } from "@/api/getData";
import { chineseNum, formatTime, formatDate } from "@/utils/datefu";
import Footer from "@/components/Footer.vue";

export default {
  components: { Footer },
  name: "Login",
  data() {
    return {
      weekDay: chineseNum(new Date().getDay()),
      time: formatTime(new Date()),
      date: formatDate(new Date()),
      ruleForm: {
        Loginname: '',
        Passwd: ''
      },
      rules: {
        Loginname: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        Passwd: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      },
      checked: false
    };
  },
  methods: {
    toIndex() {
      this.$router.replace("/");
    },
    forgotPass(){
        this.$message.warning('请联系管理员');
    },
    full() {
      var flag = false;
      if (!flag) {
        var el = document.documentElement,
          rfs =
            el.requestFullScreen ||
            el.webkitRequestFullScreen ||
            el.mozRequestFullScreen ||
            el.msRequestFullscreen;
        if (typeof rfs != "undefined" && rfs) {
          rfs.call(el);
          flag = true;
        }
        return;
      } else {
        var el = document,
          cfs =
            el.cancelFullScreen ||
            el.webkitCancelFullScreen ||
            el.mozCancelFullScreen ||
            el.exitFullScreen;
        if (typeof cfs != "undefined" && cfs) {
          cfs.call(el);
          flag = false;
        }
        return;
      }
    },
    currentTime() {
      this.weekDay = chineseNum(new Date().getDay());
      this.time = formatTime(new Date());
      this.date = formatDate(new Date());
    },
    paramsToFormData(obj) {
      const formData = new FormData();
      Object.keys(obj).forEach((key) => {
        if (obj[key] instanceof Array) {
          obj[key].forEach((item) => {
            formData.append(key, item);
          });
          return;
        }
        formData.append(key, obj[key]);
      });
      return formData;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var formData = new FormData();
          formData.append('Loginname', this.ruleForm.Loginname);
          formData.append('Passwd', this.ruleForm.Passwd);
          login(formData).then((res) => {
            console.log(res)
            const data = res.result;
            this.$store.commit('SET_GTOKEN', data.token);
            // this.$store.commit('SET_GACCOUNT', data.userName);
            this.$store.commit('SET_GNAME', data.name);
            // this.$store.commit('SET_GROLENAME', data.roleName);
            // this.$store.commit('SET_GORGID', data.orgId);
            this.$router.replace("/");
            this.$message.success('登录成功')
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
  mounted() {
    setInterval(() => {
      this.currentTime();
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
.login ::v-deep {
  .el-form-item {
    margin-bottom: 0.4rem;
  }
  .el-input__inner {
    background: url(../../assets/img/input_bg.png) no-repeat center;
    height: 0.64rem;
    border: none;
    font-size: 0.18rem;
    color: #fff;
  }
  .el-button {
    width: 4.43rem;
    height: 0.64rem;
    background: url(../../assets/img/login_submit.png) no-repeat center;
    border: none;
    margin-top: 0.5rem;
    font-size: 0.22rem;
    color: #000;
  }
  .el-form-item__error {
    font-size: 0.16rem;
    top: 110%;
  }
  .el-checkbox__label {
    font-size: 0.18rem;
    color: #fff;
  }
  .el-checkbox__input {
    vertical-align: top;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #fff;
  }
  .el-checkbox__inner {
    width: 20px;
    height: 20px;
  }
  .el-checkbox__inner::after {
    height: 9px;
    left: 6px;
    top: 2px;
    width: 5px;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #01d5f2;
    border-color: #01d5f2;
  }
}
.login {
  background: url(../../assets/img/login_bg.png) no-repeat center;
  height: 10.8rem;
  position: relative;
  .header {
    display: flex;
    justify-content: space-between;
    color: #fff;
    align-items: center;
    padding: 0.22rem 1.4rem;
    .logo {
      width: 1.68rem;
      cursor: pointer;
    }
    .date {
      display: flex;
      align-items: center; /*垂直居中*/
      .time {
        font-size: 0.38rem;
      }
      .line {
        width: 1px;
        height: 0.33rem;
        background: #ffffff;
        margin: 0 12px;
      }
      .weekday {
        font-size: 0.14rem;
      }
      .dateCnt {
        font-size: 0.14rem;
        margin-top: 0.06rem;
      }
      .full {
        margin-left: 0.4rem;
        cursor: pointer;
      }
    }
  }
  .title {
    margin: 0.85rem auto 0;
    display: block;
  }
  .formBox {
    display: flex;
    justify-content: flex-end;
    .formBg {
      width: 6.17rem;
      height: 5.77rem;
      background: url(../../assets/img/login_form_bg.png) no-repeat center;
      margin-top: 0.72rem;
      margin-right: 2.26rem;
      padding: 1.12rem 0.88rem 0;
      box-sizing: border-box;
      .aboutPassword {
        display: flex;
        justify-content: space-between;
        font-size: 0.18rem;
        color: #fff;
        p {
          cursor: pointer;
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .login{
    background-size: 100%;
    background-position: top;
    height: 10.2rem;
    .formBox{
      .formBg{
        background-size: 100%;
        margin-top: 0.3rem;
      }
    }
  }
}
</style>